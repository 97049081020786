import { ReactNode, useEffect, useState } from "react";
import Popover from "@mui/material/Popover";
import { observer } from "mobx-react";
import { FilterItem } from "types/permission";
import { Checkbox } from "@mui/material";
import styled from "styled-components";

type ColumnFilterProps = {
  anchorElement: HTMLElement;
  isOpen: boolean;
  items: FilterItem[];
  resetFilter: boolean;
  onSelect: (selectedItems: FilterItem[]) => void;
  onClose: (isOpen: boolean) => void;
  onReset: () => void;
  children?: ReactNode;
};

const ColumnFilterPopOver: React.FC<ColumnFilterProps> = observer((props) => {
  const { isOpen = false, anchorElement = null, items = [], children } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(anchorElement);
  const [open, setOpen] = useState(isOpen);
  const [selectedItems, setSelectedItems] = useState<FilterItem[]>([]);

  useEffect(() => {
    if (isOpen !== open) {
      setOpen(isOpen);
    }
  }, [isOpen]);

  useEffect(() => {
    if (anchorElement !== anchorEl) {
      setAnchorEl(anchorElement);
    }
  }, [anchorElement]);

  useEffect(() => {
    props.onSelect(selectedItems);
  }, [selectedItems]);

  useEffect(() => {
    if (props.resetFilter) {
      setSelectedItems([]);
      props.onReset();
    }
  }, [props.resetFilter]);

  const handleSelectRow = (selectedData: FilterItem) => {
    setSelectedItems((prevSelected) =>
      prevSelected.includes(selectedData)
        ? prevSelected.filter((item) => item.id !== selectedData.id)
        : [...prevSelected, selectedData]
    );
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);

    props.onClose(false);
  };

  const id = open ? "filtering-popover" : undefined;

  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={handleClose}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "left",
      }}
    >
      <MainContainer>
        {items.map((option, i) => {
          return (
            <ItemContentWrapper
              id={`Filter_Content_Wrapper_${i}`}
              key={`Filter_Content_Wrapper_${i}`}
              onClick={(e) => {
                if ((e.target as HTMLElement).tagName === "INPUT") return;
                handleSelectRow(option);
              }}
            >
              <Checkbox
                id={`Select_Option_Checkbox_${i}`}
                size="small"
                checked={selectedItems.includes(option)}
                onChange={() => handleSelectRow(option)}
              />
              <LabelName>{option.name}</LabelName>
            </ItemContentWrapper>
          );
        })}

        {children && <>{children}</>}
      </MainContainer>
    </Popover>
  );
});

const ItemContentWrapper = styled.div`
  display: flex;
  column-gap: 10px;
  align-items: center;
  padding: 14px;
  border-bottom: 1px solid var(--colorPaletteGrayBackground1);
  cursor: pointer;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const LabelName = styled.div`
  cursor: pointer;
`;

export default ColumnFilterPopOver;
