import { useCallback, useState, useEffect } from "react";
import { ENTITY_PERMISSIONS_TABS } from "../../constants/menu";
import styled from "styled-components";
import LeftNavigationBar from "./LeftNavigationBar";
import EntityPermissionsUser from "./User";
import EntityPermissionsRoles from "./Roles";
import { useLocation } from "react-router";
import NavigationTabs from "./NavigationTabs";
import store from "./store";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { observer } from "mobx-react";

const EntityPermissionsScreen = observer(() => {
  let query = useQuery();
  let nav = query.get("nav") != null ? query.get("nav") : "user";
  let idRole = query.get("idRole") != null ? query.get("idRole") : "0";

  const [activeTab, setActiveTab] = useState<string>(nav);
  const [flag, setFlag] = useState(null);

  useEffect(() => {
    store.loadFeatureFlagStatus();
  }, []);

  useEffect(() => {
    if (store.featureFlag) {
      setFlag(store.featureFlag);
    }
  }, [store.featureFlag]);

  useEffect(() => {
    setActiveTab(nav);
  }, [nav]);

  const changeTab = (tab: string) => setActiveTab(tab);

  const renderTab = useCallback(() => {
    switch (activeTab) {
      case ENTITY_PERMISSIONS_TABS.user:
        return <EntityPermissionsUser idRole={idRole} changeTab={changeTab} />;
      case ENTITY_PERMISSIONS_TABS.role:
        return <EntityPermissionsRoles changeTab={changeTab} />;
      default:
        return <EntityPermissionsUser idRole={idRole} changeTab={changeTab} />;
    }
  }, [activeTab]);

  return (
    <>
      {flag?.enabled &&
      flag?.entity_permission_v2_enabled &&
      flag?.entity_permission_v2_entity_ids.includes(StoreLayout.currentEntityId) ? (
        <MainWrapperV2>
          <NavigationTabs />
        </MainWrapperV2>
      ) : (
        <MainWrapper>
          <Column>
            <LeftNavigationBar changeTab={changeTab} activeTab={activeTab} />
          </Column>
          <ColumnContent>{renderTab()}</ColumnContent>
        </MainWrapper>
      )}
    </>
  );
});

export default EntityPermissionsScreen;

const MainWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 202px calc(100% - 220px);
  padding-top: 80px;
  grid-column-gap: 16px;
`;

const MainWrapperV2 = styled.div`
  width: 100%;
  padding: 0px 120px 120px 120px;
`;

const Column = styled.div`
  width: 230px;
`;
const ColumnContent = styled.div`
  margin: 0 50px;
`;

function useQuery() {
  return new URLSearchParams(useLocation().search);
}
