import {
  InitiateAuthCommand,
  CognitoIdentityProviderClient,
  InitiateAuthCommandInput,
  InitiateAuthCommandOutput,
  RespondToAuthChallengeCommand,
  RespondToAuthChallengeCommandInput,
  RevokeTokenCommandInput,
  RevokeTokenCommand,
  ForgotPasswordCommandOutput,
  ForgotPasswordCommand,
  ConfirmForgotPasswordCommandOutput,
  ConfirmForgotPasswordCommand,
} from "@aws-sdk/client-cognito-identity-provider";
import { COGNITO_CONFIG } from 'config/cognito';

const createCognitoService = () => {
  let username = '';
  let loginSession = '';

  const cognitoClient = new CognitoIdentityProviderClient({
    region: COGNITO_CONFIG.Region
  });

  const storeToken = (accessToken: string, refreshToken: string, rememberMeChecklisted: boolean): void => {
    localStorage.setItem('ERAccessToken', accessToken);
    localStorage.setItem('ERRefreshToken', refreshToken);
    if (rememberMeChecklisted) {
      localStorage.setItem('rememberMe', 'true');
    }
  };

  const storeTokenFromRefresh = (accessToken: string): void => {
    localStorage.setItem('ERAccessToken', accessToken);
  }

  const getAccessToken = (): string | null => {
    return localStorage.getItem('ERAccessToken');
  };

  const getRefreshToken = (): string | null => {
    return localStorage.getItem('ERRefreshToken');
  };

  const login = async (
    inputUsername: string,
    password: string,
    rememberMeChecklisted: boolean,
  ): Promise<InitiateAuthCommandOutput> => {
    const params: InitiateAuthCommandInput = {
      AuthFlow: 'USER_PASSWORD_AUTH',
      AuthParameters: {
        USERNAME: inputUsername,
        PASSWORD: password
      },
      ClientId: COGNITO_CONFIG.ClientId
    };

    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command) as InitiateAuthCommandOutput;

    username = inputUsername;

    if (response.ChallengeName) {
      loginSession = response.Session ?? '';
    }

    if (response.AuthenticationResult) {
      storeToken(
        response.AuthenticationResult.AccessToken ?? '',
        response.AuthenticationResult.RefreshToken ?? '',
        rememberMeChecklisted
      );
    }

    return response;
  };

  const setNewPassword = async (
    newPassword: string
  ): Promise<InitiateAuthCommandOutput> => {
    const params: RespondToAuthChallengeCommandInput = {
      ChallengeName: 'NEW_PASSWORD_REQUIRED',
      ClientId: COGNITO_CONFIG.ClientId,
      ClientMetadata:{
        POST_FIRST_SETUP: 'true'
      },
      ChallengeResponses: {
        USERNAME: username,
        NEW_PASSWORD: newPassword
      },
      Session: loginSession,
    };

    const respondToAuthChallengeCommand = new RespondToAuthChallengeCommand(params);
    const response = await cognitoClient.send(respondToAuthChallengeCommand);

    if (response.AuthenticationResult) {
      storeToken(
        response.AuthenticationResult.AccessToken ?? '',
        response.AuthenticationResult.RefreshToken ?? '',
        false,
      );
    }

    return response;
  };

  const refreshToken = async (): Promise<InitiateAuthCommandOutput> => {
    if (!getRefreshToken()) {
      throw new Error('No refresh token found');
    }

    if (localStorage.getItem('rememberMe') !== 'true') {
      throw new Error('No remember me found');
    }

    const params: InitiateAuthCommandInput = {
      AuthFlow: 'REFRESH_TOKEN_AUTH',
      ClientId: COGNITO_CONFIG.ClientId,
      AuthParameters: {
        REFRESH_TOKEN: getRefreshToken() || ''
      }
    };

    const command = new InitiateAuthCommand(params);
    const response = await cognitoClient.send(command) as InitiateAuthCommandOutput;

    if (response.AuthenticationResult) {
      storeTokenFromRefresh(
        response.AuthenticationResult.AccessToken ?? ''
      );
    }

    return response;
  };

  const logout = async (): Promise<void> => {
    try {
      const refreshToken = getRefreshToken();
      if (!refreshToken) {
        throw new Error('No refresh token found');
      }

      const params: RevokeTokenCommandInput = {
        Token: getRefreshToken(),
        ClientId: COGNITO_CONFIG.ClientId
      };
  
      const command = new RevokeTokenCommand(params);
      await cognitoClient.send(command);
    } catch (error) {
      console.error('Error revoking token', error);
    }
    

    localStorage.removeItem('ERAccessToken');
    localStorage.removeItem('ERRefreshToken');
    window.location.href = '/login';
  };


  const requestNewPassword = async (email: string): Promise<ForgotPasswordCommandOutput> => {
    const params = {
      ClientId: COGNITO_CONFIG.ClientId,
      Username: email
    };

    const command = new ForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);

    return response
  };

  const confirmNewPassword = async (email: string, code: string, newPassword: string): Promise<ConfirmForgotPasswordCommandOutput> => {
    const params = {
      ClientId: COGNITO_CONFIG.ClientId,
      Username: email,
      ConfirmationCode: code,
      Password: newPassword
    };

    const command = new ConfirmForgotPasswordCommand(params);
    const response = await cognitoClient.send(command);
    return response
  }

  return {
    login,
    logout,
    refreshToken,
    setNewPassword,
    requestNewPassword,
    confirmNewPassword,
    getAccessToken,
    getRefreshToken,
  };
};

export const cognitoService = createCognitoService();
export type CognitoService = ReturnType<typeof createCognitoService>;
