import { makeAutoObservable } from "mobx";
import { getUsersGroupedByRoles } from "../../api/go/useGetUsersGroupedByRoles";
import StoreLayout from "components/workspaces-sidebar/StoreLayout";
import { EntityUsers, FilterItem, RoleUser, User } from "types/permission";
import MainStore from "MainStore";
import { ACCESS_LEVEL_TYPE } from "constants/access-level-type";
import { getEntityUsers } from "api/entityPermission/useGetEntityUsers";
import { getEntityUserRoleFilters } from "api/entityPermission/useGetRoleFilters";
import { getFeatureFlagStatus } from "api/featureFlag/useGetFeatureFlag";

class Store {
  roleData: RoleUser[] = [];
  invitedUsers: EntityUsers;
  selectedData: number[] = [];
  currentUser: User = null;
  activeTab: string = "entityUsers";
  currentRoleName = "";

  currentUserId: number = 0;
  currentRoleId = 0; //TODO: need to be removed once invited user functionality works
  tabId: number = 0;
  
  addEditRoleNamePanel = false;
  changeRolePanel = false;
  deleteRolePanel = false;
  inviteUsersPanel = false;
  removeFromRolePanel = false;
  inviteAgainPanel = false;
  checkEmployeePanel = false;

  sortAttributeId: number = null;
  
  currentPage: number = 1;
  pageSize: number = 10;
  sortType: string = "asc";
  sortField: string = "";
  filterByRoles: string = "";
  searchedValue = "";
  resultSearchValue = "";

  roleFilters: FilterItem[];
  featureFlag: any = null;

  constructor() {
    makeAutoObservable(this);
  }

  changeResultSearchValue = (value: string) => {
    this.resultSearchValue = value;
  };
  
  changeSearchedValue = (value: string) => {
    this.searchedValue = value;
  };

  changeTabId = (id: number) => {
    this.tabId = id;
  };

  changeCurrentUser = (user: User) => {
    this.currentUser = user;
  };

  changeSelectedData = (data: number[]) => {
    this.selectedData = data;
  };

  changeCurrentRoleName = (name: string) => {
    this.currentRoleName = name;
  };

  changeCurrentRoleId = (id: number) => {
    this.currentRoleId = id;
  };

  changeAddEditRoleNamePanel = (flag: boolean) => {
    this.addEditRoleNamePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
    }
  };

  changeDeleteRolePanel = (flag: boolean) => {
    this.deleteRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeCheckEmployeePanel = (flag: boolean) => {
    this.checkEmployeePanel = flag;

    if (!flag) {
      this.currentUserId = 0;
    }
  };

  changeRemoveFromRolePanel = (flag: boolean) => {
    this.removeFromRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
      this.currentRoleName = "";
      this.selectedData = [];
    }
  };

  changeChangeRolePanel = (flag: boolean) => {
    this.changeRolePanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeInviteUsersPanel = (flag: boolean) => {
    this.inviteUsersPanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  changeInviteAgainPanel = (flag: boolean) => {
    this.inviteAgainPanel = flag;

    if (!flag) {
      this.currentRoleId = 0;
    }
  };

  setData = (value: any, field: string) => {
    this[field] = value;
  };

  onSaved = () => {
    this.changeAddEditRoleNamePanel(false);
    this.loadData();
  };

  onRoleChanged = () => {
    this.changeChangeRolePanel(false);
    this.loadData();
  };
  onRemovedFromRole = () => {
    this.changeRemoveFromRolePanel(false);
    this.loadData();
  };

  onRoleDeleted = () => {
    this.changeDeleteRolePanel(false);
    this.loadData();
    this.tabId = 0;
  };

  onIvitedUsers = () => {
    this.changeInviteUsersPanel(false);
    this.loadData();
  };

  onInvitedAgain = () => {
    this.changeInviteAgainPanel(false);
    this.loadData();
  };

  keyPress = (e: any) => {
    if (e.keyCode === 13) {
      this.onSearchClicked();
    }
  };

  onSearchClicked = () => {
    this.resultSearchValue = this.searchedValue;
    this.searchedValue = "";

    this.loadEntityUsers();
  };

  clearStore() {
    this.roleData = [];
    this.selectedData = [];
    this.roleFilters = [];
  }

  changeSort = (
    field: string,
    type: string,
  ) => {
    this.sortField = field;
    this.sortType = type;

    this.loadEntityUsers();
  };

  async loadEntityUsers() {
    try {
      const response = await getEntityUsers(
        StoreLayout.currentEntityId,
        this.filterByRoles,
        this.currentPage,
        this.pageSize,
        this.sortType,
        this.sortField,
        this.resultSearchValue,
      );

      this.invitedUsers = response.data;
      this.currentPage = this.invitedUsers.current_page;
      this.pageSize = this.invitedUsers.page_size;

      if (this.resultSearchValue) {
        this.resultSearchValue = "";
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadEntityRoleFilters() {
    try {
      const response = await getEntityUserRoleFilters(
        StoreLayout.currentEntityId,
      );

      this.roleFilters = response.data.user_roles;
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadFeatureFlagStatus() {
    try {
      const response = await getFeatureFlagStatus("entity_permission_v2");

      if (response) {
        this.featureFlag = response.data;
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }

  async loadData() {
    try {
      const response = await getUsersGroupedByRoles(
        StoreLayout.currentEntityId,
        ACCESS_LEVEL_TYPE.entity,
        this.resultSearchValue,
        StoreLayout.currentRegionId
      );
      if ((response.status === 201 || response.status === 200) && response?.data !== null) {
        this.roleData = response.data.roles;
      } else {
        throw new Error();
      }
    } catch (err) {
      MainStore.setSnackbar("Something went wrong!", "error");
    }
  }
}

const store = new Store();
export default store;
