import { FC, useCallback, useEffect } from "react";
import styled from "styled-components";
import store from "./store";
import { observer } from "mobx-react";
import { ENTITY_PERMISSION_MENU, ENTITY_PERMISSION_MENU_VALUES } from "constants/entityPermissionMenu";
import EntityUserScreen from "./UserV2/EntityUserScreen";

const NavigationTabs = observer((props) => {
  useEffect(() => {
    store.loadData().then(() => {
      if (props.idRole === "0") return;
      let roleIndex = store.roleData.map((x) => x.id).indexOf(+props.idRole);
      if (roleIndex === -1) {
        return;
      }
      store.changeTabId(roleIndex + 1);
    });

    store.loadEntityUsers();
    store.loadEntityRoleFilters();
  }, []);

  const changeMenu = (menuItem: { label: string; value: string }) => {
    store.setData(menuItem.value, "activeTab");

    switch (menuItem.value) {
      case ENTITY_PERMISSION_MENU_VALUES.entityUsers:
        store.loadEntityUsers();
        store.loadEntityRoleFilters();
        break;
      
      case ENTITY_PERMISSION_MENU_VALUES.entityEmployeePermissions:
        break;
    
      default:
        break;
    }
  }

  const renderScreen = useCallback(() => {
    switch (store.activeTab) {
      case ENTITY_PERMISSION_MENU_VALUES.entityUsers:
        return <EntityUserScreen />

      case ENTITY_PERMISSION_MENU_VALUES.entityEmployeePermissions:
        return <></>;

      default:
        return <></>;
    }
  }, [store.activeTab])

  return (
    <>
      <MenuContainer>
        {ENTITY_PERMISSION_MENU.map((menuItem, index, array) => {
          return (
            <CustomMenuItem
              key={`Menu_Item_${index}`}
              $isDisabled={false}
              $first={index === 0}
              $last={index === array.length - 1}
              $active={store.activeTab === menuItem.value}
              onClick={() => changeMenu(menuItem)}
            >
              {menuItem.label}
            </CustomMenuItem>
          );
        })}
      </MenuContainer>

      <Content>
        {renderScreen()}
      </Content>
    </>
  );
});

const MenuContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  column-gap: 32px;
  padding-top: 56px;
  border-bottom: 1px solid var(--graySelectBoxBorder);
`;

const CustomMenuItem = styled.span<{
  $isDisabled: boolean;
  $active?: boolean;
  $first?: boolean;
  $last?: boolean;
}>`
  margin: ${(props) =>
    props.$first ? `0px 12px 0px 0px` : props.$last ? `0px 0px 0px 12px` : `0px 12px`};
  padding: 12px 0px;
  cursor: ${(props) => (props.$isDisabled ? "not-allowed" : "pointer")};
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  letter-spacing: 0em;
  color: ${(props) =>
    props.$isDisabled
      ? "var(--colorNeutralForeground5, #959ba2)"
      : props.$active
      ? `var(--colorBrandForeground1)`
      : `var(--colorPaletteVioletBackground1)`};
  ${(props) => props.$active && `border-bottom: 4px solid var(--colorBrandForeground1)`};
`;

const Content = styled.div`
  padding-top: 32px;
`

export default NavigationTabs;
