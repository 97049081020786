import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import {
  FormControlLabel,
  Checkbox,
  TextField,
  InputAdornment,
  IconButton,
  Button,
} from "@mui/material";
import CustomButton from "components/button/Button";
import Pagination from "components/pagination/Pagination";
import CustomTable from "components/customTable";
import ColumnFilterPopOver from "components/columnFilterPopOver";
import { TColumn } from "types/recipients";
import storeInviteUsers from "./InviteUsers/store";
import store from "../store";
import styled from "styled-components";
import { EntityUser, EntityUsers, FilterItem, User } from "types/permission";
import { InviteFormModal } from "components/invite-user-popup/InviteFormModal";
//import InviteFormModal from "components/inviteUserPopupV2/InviteFormModal";
import ChangeRolePopupForm from "../Roles/ChangeRole/popupChangeRole";
import InviteAgainForm from "./InviteAgain/InviteAgainForm";
import PersonRemoveIcon from "@mui/icons-material/PersonRemove";
import SearchIcon from "@mui/icons-material/Search";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const EntityUserScreen = observer(() => {
  const [inviteUsersPanel, setInviteUsersPanel] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [currentEl, setCurrentEl] = useState<HTMLElement | null>(null);
  const [isReset, setIsReset] = useState(false);
  const [selectedFilters, setSelectedFilters] = useState<FilterItem[]>([]);
  const [currentRecords, setCurrentRecords] = useState<EntityUsers>(null);

  useEffect(() => {
    setCurrentRecords(store.invitedUsers);
  }, [store.invitedUsers]);

  useEffect(() => {
    store.loadEntityUsers();
  }, [store.currentPage, store.pageSize]);

  const selectionChangeHandler = (isSelected?: boolean, row?: EntityUser) => {
    const newSelection = isSelected
      ? store.selectedData.filter((id) => id !== row.id)
      : [...store.selectedData, row.id];
    store.changeSelectedData(newSelection);
  };

  const handleSelectFilter = (selectedItem: FilterItem[]) => {
    setSelectedFilters(selectedItem);
  };

  const handleApplyFilter = () => {
    if (selectedFilters.length > 0) {
      store.setData(1, "currentPage");
    }

    const joinedRoleIds = selectedFilters.map((item) => item.id).join(",");

    store.setData(joinedRoleIds, "filterByRoles");
    store.loadEntityUsers();
  };

  const handleResetFilter = () => {
    setIsReset(false);
    setSelectedFilters([]);
  };

  const handleClearAll = () => {
    setIsReset(true);
  };

  const columns = [
    {
      width: 20,
      label: (
        <CheckBoxContainer>
          <StyledFormControlLabel
            control={
              <StyledCheckBox
                id={`EntityPermissions_EntityUsers_Grid_Table_ChecboxAll`}
                disabled={currentRecords?.users.length === 0}
                checked={store.selectedData.length === currentRecords?.users.length}
                onChange={() => {
                  store.changeSelectedData(
                    store.selectedData.length !== currentRecords?.users.length
                      ? currentRecords?.users.map((item) => item.id)
                      : []
                  );
                }}
              />
            }
            label={false}
          />
        </CheckBoxContainer>
      ),
      render: (row: any, index, indexRow) => {
        const isSelected = store.selectedData.includes(row.id);
        return (
          <CheckBoxContainer>
            <StyledFormControlLabel
              control={
                <StyledCheckBox
                  id={`EntityPermissions_EntityUsers_Grid_Table_Checbox_${index}_${indexRow}_${row.id}`}
                  checked={isSelected}
                />
              }
              label={false}
            />
          </CheckBoxContainer>
        );
      },
    },
    {
      id: "full_name",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p>Name</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Name_Sort_Asc"
              style={
                store.sortField === "name" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("name", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Name_Sort_Desc"
              style={
                store.sortField === "name" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("name", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "email",
      width: 400,
      label: (
        <StyledContainerLabelColumn>
          <p>Email</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Email_Sort_Asc"
              style={
                store.sortField === "email" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("email", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Email_Sort_Desc"
              style={
                store.sortField === "email" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("email", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
    },
    {
      id: "user_roles",
      width: 450,
      label: (
        <MainContainerColumnFilter>
          <StyledContainerLabelColumn>
            <p>User Roles</p>
            <img
              src={"/assets/icons/column-filter-icon.svg"}
              width={24}
              height={24}
              alt="column filter"
              style={{ cursor: "pointer" }}
              onClick={(event) => {
                setOpenFilter(true);
                setCurrentEl(event.currentTarget);
              }}
            />
          </StyledContainerLabelColumn>
          <ColumnFilterPopOver
            anchorElement={currentEl}
            isOpen={openFilter}
            items={store.roleFilters?.length > 0 ? store.roleFilters : []}
            resetFilter={isReset}
            onSelect={handleSelectFilter}
            onClose={() => {
              setOpenFilter(false);
              setCurrentEl(null);
            }}
            onReset={handleResetFilter}
          >
            <PopoverFooterContainer>
              <CustomButton
                name="ApplyBtn"
                color="primary"
                variant="contained"
                onClick={handleApplyFilter}
              >
                Apply filter
              </CustomButton>
              <CustomButton
                name="ClearBtn"
                variant="text"
                onClick={handleClearAll}
                disabled={selectedFilters.length < 1}
              >
                Clear all
              </CustomButton>
            </PopoverFooterContainer>
          </ColumnFilterPopOver>
        </MainContainerColumnFilter>
      ),
      render: (row: any) => {
        const maxCharacterLimit = 60;

        const rolesString = row.user_roles.join(", ");
        const isExceedingLimit = rolesString.length > maxCharacterLimit;
        const truncatedRoles = `${rolesString.slice(0, maxCharacterLimit)}...`;

        const isExpanded = row.isExpanded || false;

        const handleToggle = () => {
          row.isExpanded = !isExpanded;
        };
        return (
          <RolesWrapper>
            <RoleLabel>{isExpanded || !isExceedingLimit ? rolesString : truncatedRoles}</RoleLabel>
            {isExceedingLimit && (
              <Button
                variant="text"
                disableRipple={true}
                sx={{
                  justifyContent: "flex-start",
                  textTransform: "none",
                  padding: "0px",
                  "&:hover": {
                    backgroundColor: "transparent",
                  },
                }}
                onClick={handleToggle}
              >
                <label style={{ textAlign: "left", cursor: "pointer" }}>
                  {isExpanded ? "View less" : "View more"}
                </label>
                {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </Button>
            )}
          </RolesWrapper>
        );
      },
    },
    {
      id: "status",
      width: 350,
      label: (
        <StyledContainerLabelColumn>
          <p>Status</p>
          <BlockSortStyle>
            <KeyboardArrowUpIconStyle
              id="EntityPermission_Table_Status_Sort_Asc"
              style={
                store.sortField === "status" && store.sortType === "asc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("status", "asc")}
            />
            <PlugStyle />
            <KeyboardArrowDownIconStyle
              id="EntityPermission_Table_Status_Sort_Desc"
              style={
                store.sortField === "status" && store.sortType === "desc"
                  ? { color: "var(--colorBrandForeground1)" }
                  : null
              }
              onClick={() => store.changeSort("status", "desc")}
            />
          </BlockSortStyle>
        </StyledContainerLabelColumn>
      ),
      render: (param: any) => {
        return (
          <StatusWrapper $status={param.status.toLowerCase()}>
            <label>{param.status}</label>
          </StatusWrapper>
        );
      },
    },
  ] as TColumn[];

  const handleEditUserRolesClick = () => {
    store.changeChangeRolePanel(true);

    const selectedUsers = store.roleData.reduce<User[]>(
      (acc, role) => [...acc, ...role.users.filter((user) => store.selectedData.includes(user.id))],
      []
    );

    if (selectedUsers.length === 1) {
      store.changeCurrentUser(selectedUsers[0]);
    }
  };

  const handleIsRowSelected = (row: any) => {
    return store.selectedData.includes(row.id);
  };

  return (
    <>
      <FlexJustifyBetweenBox>
        <Header id="EntityPermissions_EntityUsers_Title">Entity users</Header>
        <CustomButton
          id={`EntityPermissions_EntityUsers_Grid_Table_AddUsersBtn`}
          style={{ margin: 5 }}
          variant="contained"
          size="small"
          onClick={() => {
            setInviteUsersPanel(true);
            //store.changeCurrentRoleId(props.data.id);
          }}
        >
          Invite users
        </CustomButton>
      </FlexJustifyBetweenBox>

      <FlexJustifyBetweenBox>
        <FlexBox>
          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_Table_ChangeRoleBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="contained"
            color="inherit"
            size="small"
            onClick={handleEditUserRolesClick}
          >
            Re-assign user roles
          </CustomButton>

          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_InviitAgainBtn`}
            style={{ margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="contained"
            color="inherit"
            size="small"
            onClick={() => {
              store.changeInviteAgainPanel(true);
            }}
          >
            Resend Invitation
          </CustomButton>

          <CustomButton
            id={`EntityPermissions_EntityUsers_Grid_RevokeUserBtn`}
            style={{ display: "flex", columnGap: "4px", margin: 5 }}
            disabled={store.selectedData.length === 0}
            variant="text"
            color="inherit"
            size="small"
          >
            <PersonRemoveIcon fontSize="small" />
            Revoke users
          </CustomButton>
        </FlexBox>

        <TextField
          id="EntityPermissions_EntityUsers_SearchInput"
          sx={{
            minWidth: 440,
            "& .MuiInputBase-input": {
              padding: "8px",
            },
            "& .MuiInputBase-root": {
              background: "white",
            },
          }}
          placeholder="Search for name/email"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  id="EntityPermissions_EntityUsers_SearchBtn"
                  onClick={() => {
                    store.onSearchClicked();
                  }}
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          }}
          value={store.searchedValue}
          onKeyDown={store.keyPress}
          onChange={(e) => store.changeSearchedValue(e.target.value)}
        />
      </FlexJustifyBetweenBox>

      <TableContainer>
        <div style={{ width: "100%" }}>
          <CustomTable
            data={currentRecords ? currentRecords.users : []}
            columns={columns}
            onGetIsRowSelected={handleIsRowSelected}
            changeHandler={selectionChangeHandler}
            noDataTitle={{
              title: "No users yet.",
              description: "Get started by adding users.",
            }}
          />
        </div>
        <Pagination
          id={`EntityPermission_UsersGridInvite_InviteAgain`}
          activePage={store.currentPage}
          itemsCountPerPage={store.pageSize}
          onChange={(value) => {
            store.setData(value, "currentPage");
          }}
          pageRangeDisplayed={5}
          totalItemsCount={currentRecords?.count}
          totalPages={currentRecords?.total_page}
          setCount={(value) => {
            store.setData(1, "currentPage");
            store.setData(Number(value), "pageSize");
          }}
        />
      </TableContainer>

      <ChangeRolePopupForm
        id="EntityPermissions_CjangeRoleFormPopUp"
        openPanel={store.changeRolePanel}
        roles={store.roleData}
        onBtnCancelClick={() => store.changeChangeRolePanel(false)}
        isDeleteRole={false}
        countUsers={store.selectedData.length}
        userIds={store.selectedData}
        roleName={store.currentRoleName}
        userEmail={store.currentUser?.email}
        onClose={() => store.changeChangeRolePanel(false)}
        onSaved={() => {
          store.onRoleChanged();
        }}
        idRole={store.currentRoleId}
      />

      <InviteFormModal
        open={inviteUsersPanel}
        onClose={() => setInviteUsersPanel(false)}
        roleName={store.roleData.find((el) => el.id === store.currentRoleId)?.roleName}
        roleId={store.currentRoleId}
        inviteHandler={() => {
          storeInviteUsers.inviteUserSubmit(store.currentRoleId, () => {
            store.onIvitedUsers();
            store.changeInviteUsersPanel(false);
          });
        }}
        emails={storeInviteUsers.emails}
        value={storeInviteUsers.value}
        onChange={(value) => storeInviteUsers.changeFieldValue(value)}
        submitHandler={() => storeInviteUsers.addEmail()}
        deleteEmail={(index) => storeInviteUsers.deleteEmail(index)}
        clearStates={() => {
          storeInviteUsers.changeFieldValue("");
          storeInviteUsers.changeEmail([]);
        }}
      />

      {/* TODO: Need to use this new version component once endpoint ready */}
      {/* <InviteFormModal
        open={inviteUsersPanel}
        onClose={() => setInviteUsersPanel(false)}
        roleName={store.roleData.find((el) => el.id === store.currentRoleId)?.roleName}
        roleId={store.currentRoleId}
        inviteHandler={() => {
          //TODO: Update logic once new endpoint available
          storeInviteUsers.inviteUserSubmit(store.currentRoleId, () => {
            store.onIvitedUsers();
            store.changeInviteUsersPanel(false);
          });
        }}
        emails={storeInviteUsers.emails}
        value={storeInviteUsers.value}
        roleOptions={store.roleData}
        onChange={(value) => storeInviteUsers.changeFieldValue(value)}
        submitHandler={() => storeInviteUsers.addEmail()}
        deleteEmail={(index) => storeInviteUsers.deleteEmail(index)}
        clearStates={() => {
          storeInviteUsers.changeFieldValue("");
          storeInviteUsers.changeEmail([]);
        }}
      /> */}

      <InviteAgainForm
        id="EntityPermissions_InviteAgainPopUp"
        openPanel={store.inviteAgainPanel}
        onBtnCancelClick={() => store.changeInviteAgainPanel(false)}
        countUsers={store.selectedData.length}
        idUsers={store.selectedData}
        onClose={() => store.changeInviteAgainPanel(false)}
        onSaved={() => {
          store.onInvitedAgain();
        }}
      />
    </>
  );
});

const FlexJustifyBetweenBox = styled.div`
  display: flex;
  justify-content: space-between;
  padding-bottom: 24px;
`;

const FlexBox = styled.div`
  display: flex;
  column-gap: 16px;
`;

const TableContainer = styled.div`
  width: 100%;
  padding-top: 24px;
`;

const Header = styled.h1`
  font-family: Roboto;
  font-size: 32px;
  font-weight: 600;
  line-height: 36px;
  text-align: left;
  color: var(--colorNeutralForeground1);
  margin: 0px;
`;

const CheckBoxContainer = styled.div``;

const StyledCheckBox = styled(Checkbox)`
  padding: 0px 10px !important;
  color: var(--colorBrandForeground1) !important;
`;

const StyledFormControlLabel = styled(FormControlLabel)`
  font-family: Roboto;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  color: var(--colorNeutralForeground1);

  .MuiCheckbox-root {
    &:hover {
      background-color: transparent !important;
    }
  }
`;

const StatusWrapper = styled.div<{ $status: "active" | "pending" | "expired" }>`
  display: flex;
  justify-content: center;
  border: 1px solid;
  border-radius: 4px;
  width: 69px;
  padding: 4px 12px 4px 12px;

  background-color: ${({ $status }) =>
    $status === "active"
      ? "var(--colorPaletteGreenBackground6)"
      : $status === "pending"
      ? "var(--colorPaletteRedForeground5)"
      : "var(--grayD9DDE7)"};

  border-color: ${({ $status }) =>
    $status === "active"
      ? "var(--colorPaletteGreenBackground6)"
      : $status === "pending"
      ? "var(--colorPaletteRedForeground5)"
      : "var(--grayD9DDE7)"};

  label {
    font-weight: 500px;
    color: var(--colorPaletteVioletBackground1);
    text-transform: capitalize;
  }
`;

const StyledContainerLabelColumn = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;

const MainContainerColumnFilter = styled.div`
  position: relative;
`;

const BlockSortStyle = styled("span")`
  display: flex;
  flex-direction: column;
  position: relative;
  width: 10px;
  height: 20px;
  top: 0;
  left: 14px;

  svg {
    width: 20px !important;
    height: 20px !important;
  }
`;

const KeyboardArrowUpIconStyle = styled(KeyboardArrowUpIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: -6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const KeyboardArrowDownIconStyle = styled(KeyboardArrowDownIcon)`
  color: var(--colorNeutralForeground2);
  position: absolute;
  top: 6px;
  left: -5px;
  cursor: pointer;

  &:hover {
    background-color: transparent;
  }
`;

const PlugStyle = styled("div")`
  width: 20px;
  height: 8px;
  background-color: transparent;
  position: absolute;
  top: 6px;
  left: -5px;
  z-index: 999;
`;

const PopoverFooterContainer = styled.div`
  display: flex;
  column-gap: 16px;
  padding: 14px;
`;

const RolesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 4px;
`;

const RoleLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #000000de;
`;

export default EntityUserScreen;
